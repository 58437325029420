<template>
  <div
    style="overflow: hidden; box-sizing: border-box"
    :style="{ background: bgColor }"
  >
    <div class="members">
      <img class="members-bg" src="../../assets/images/members-bg.png" />
      <div style="position: relative; z-index: 1">
        <div class="core" v-if="config && config.core">
          <div class="title-box">
            <img class="icon1" src="../../assets/images/core-icon.png" />
            <div class="title">
              <div class="txet">核心成员</div>
            </div>
          </div>

          <div class="core-scroll">
            <div class="flex core-list">
              <div class="core-item" v-for="item in config.core" :key="item.id">
                <div class="flex">
                  <div class="core-img">
                    <img
                      style="object-fit: cover"
                      class="img"
                      :src="item.image"
                    />
                  </div>
                  <div style="margin-left: 55px">
                    <div class="flex-align-center">
                      <div class="name">{{ item.name }}</div>
                      <div class="position">
                        <img
                          class="icon"
                          src="../../assets/images/position-icon.png"
                        />
                        {{ item.position }}
                      </div>
                    </div>
                    <div class="send-word">{{ item.send_word }}</div>
                  </div>
                </div>
                <div v-if="item.description" class="description-bg">
                  <div class="description">{{ item.description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="essence" v-if="config.display">
          <div class="title-box">
            <img class="icon1" src="../../assets/images/active-icon.png" />
            <div class="title">
              <div class="txet">精华{{ displayInfo.name }}</div>
            </div>
          </div>
          <div class="essence-box">
            <div class="title flex-align-center flex-between">
              <div class="left flex-align-center">
                <img
                  v-show="config.display === 'active'"
                  class="icon"
                  src="../../assets/images/active-title-icon.png"
                />
                <img
                  v-show="config.display === 'activities'"
                  class="icon"
                  src="../../assets/images/activities-title-icon.png"
                />
                <img
                  v-show="config.display === 'cyc_circles'"
                  class="icon"
                  src="../../assets/images/cyc-circles-title-icon.png"
                />
                {{ displayInfo.title }}
              </div>
              <div class="right flex-align-center">
                <img class="icon" src="../../assets/images/key-icon.png" />
                解锁{{ displayInfo.name }}
              </div>
            </div>
            <div class="content">
              <div v-show="config.display === 'active'" class="flex">
                <div
                  class="member-list"
                  v-for="item in config.active.slice(0, 1)"
                  :key="item.id"
                >
                  <div
                    class="member-box flex"
                    v-for="item2 in item.children.slice(0, 3)"
                    :key="item2.id"
                  >
                    <img
                      class="avatar"
                      style="object-fit: cover"
                      :src="item2.image"
                    />
                    <div>
                      <div class="flex-align-center">
                        <div class="name">{{ item2.name }}</div>
                        <div class="position">
                          {{ item2.unit_name || item2.position }}
                        </div>
                      </div>
                      <div class="reputation flex-align-center">
                        <div class="icon-box">
                          <img
                            class="icon"
                            src="../../assets/images/reputation-icon.png"
                          />
                        </div>
                        <div class="text">{{ item2.reputation }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="config.display === 'activities'" class="activities">
                <div class="tab-list">
                  <div
                    class="tab-box flex-align-center"
                    :class="[index === 0 ? 'active' : '']"
                    v-for="(item, index) in config.activities"
                    :key="item.id"
                  >
                    {{ item.name }}
                  </div>
                </div>
                <div v-for="item in displayInfo.children" :key="item.id">
                  <div
                    :key="item.id"
                    v-show="item.photos"
                    class="history-activities"
                  >
                    <div
                      class="history-activities-photos flex"
                      v-if="item.photos && item.photos.length"
                    >
                      <img
                        style="object-fit: cover"
                        class="photos-cover"
                        :src="item.photos[0]"
                      />
                      <div class="photos-list">
                        <div
                          v-for="(image, index) in item.photos.slice(1, 4)"
                          :key="index"
                          class="photos-item"
                        >
                          <img style="object-fit: cover" :src="image" />
                          <template v-if="index === 2 && item.photo_count > 4">
                            <div class="photo-counts flex-center">
                              +{{ item.photo_count }}
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                    <div class="flex-align-center history-activities-name">
                      <div class="flex-1">{{ item.name }}</div>
                      <img
                        src="../../assets/images/history-activities-name.png"
                      />
                    </div>
                    <div class="flex">
                      <div class="history-activities-type">
                        <img
                          class="icon"
                          src="../../assets/images/categories-icon.png"
                        />
                        {{ item.categories }}
                      </div>
                      <div
                        style="margin-left: 4px"
                        class="history-activities-users-counts"
                      >
                        <img
                          class="icon"
                          src="../../assets/images/users-counts-icon.png"
                        />
                        {{ item.activity_users_count }}人参会
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-show="config.display === 'cyc_circles'"
                class="cyc-circles"
              >
                <div
                  class="cyc-circles-box"
                  v-for="item in displayInfo.children"
                  :key="item.id"
                >
                  <div class="flex">
                    <el-image
                      class="avatar"
                      :src="item.avatar"
                      fit="cover"
                    ></el-image>
                    <div class="flex-align-center">
                      <div class="flex-align-center">
                        <div class="name flex-1">{{ item.nickname }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="content-text">{{ item.content_text }}</div>
                  <template v-if="item.res && item.res.length">
                    <div class="res-list">
                      <img
                        v-for="img in item.res"
                        style="object-fit: cover"
                        :key="img.url"
                        :src="img.url"
                        class="res-img"
                      />
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ListImage from '@/base/components/List/ListImage'
export default {
  components: { ListImage },
  props: {
    config: Object,
    bgColor: String,
  },
  data() {
    return {}
  },

  computed: {
    displayInfo() {
      switch (this.config.display) {
        case 'active':
          return {
            name: '人脉',
            title: this.config?.active[0]?.name
              ? this.config.active[0].name
              : '',
          }
        case 'activities':
          return {
            name: '活动',
            title: '最受欢迎',
            children: this.config?.activities[0]?.children
              ? this.config.activities[0].children
              : [],
          }
        case 'cyc_circles':
          return {
            name: '资源',
            title: this.config?.cyc_circles?.name
              ? this.config.cyc_circles.name
              : '',
            children: this.config?.cyc_circles?.children
              ? this.config.cyc_circles.children
              : [],
          }
        default:
          return {
            name: '',
            title: '',
            children: [],
          }
      }
    },
  },
  methods: {},
}
</script>
<style lang="scss" scoped>
.members {
  box-sizing: border-box;
  padding: 16 * 0.75px;
  width: 100%;
  padding-right: 0;
  position: relative;
  z-index: 2;
  background: linear-gradient(
      0deg,
      rgba(255, 241, 207, 0.7) 0%,
      rgba(255, 241, 207, 0.7) 100%
    ),
    #fff;
  border-radius: 16 * 0.75px;
  overflow: hidden;
  transform: translateY(10 * 0.75px);
  .members-bg {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
  }
  .title-box {
    display: flex;
    margin-bottom: 10 * 0.75px;

    .icon1 {
      width: 24 * 0.75px;
      height: 24 * 0.75px;
      margin-right: 8 * 0.75px;
    }
    .title {
      position: relative;
      font-weight: bold;
      font-size: 18 * 0.75px;
      color: #5e370a;
      .txet {
        position: relative;
        z-index: 1;
      }
    }
  }
  .core {
    margin-bottom: 16 * 0.75px;
    .core-scroll {
      width: 100%;
      // overflow: hidden;
      .core-list {
        height: 142 * 0.75px;
        width: max-content;
        .core-item {
          width: 317 * 0.75px;
          height: 142 * 0.75px;
          background-color: #fff;
          padding: 16 * 0.75px;
          // overflow: hidden;
          border-radius: 16 * 0.75px;
          margin-right: 12 * 0.75px;
          position: relative;
          .core-img {
            position: absolute;
            padding-top: 4px;
            background-color: #fff;
            border-radius: 999em;
            top: -8 * 0.75px;
            .img {
              width: 63 * 0.75px;
              height: 63 * 0.75px;
              border-radius: 999em;
            }
          }
          .name {
            font-size: 14 * 0.75px;
            font-weight: bold;
            margin-right: 8 * 0.75px;
          }
          .position {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 20 * 0.75px;
            padding: 0 5 * 0.75px;
            background-color: rgba(48, 43, 41, 1);
            color: rgba(234, 190, 158, 1);
            font-size: 11 * 0.75px;
            border-radius: 4 * 0.75px;
            .icon {
              width: 12 * 0.75px;
              height: 10 * 0.75px;
              margin-right: 4 * 0.75px;
            }
          }
          .send-word {
            margin-top: 8 * 0.75px;
            line-height: 18 * 0.75px;
            font-size: 12 * 0.75px;
            color: rgba(171, 173, 175, 1);
            @include clamp(1);
          }
          .description-bg {
            padding: 12 * 0.75px;
            margin-top: 8 * 0.75px;
            border: 1px solid rgba(252, 244, 222, 1);
            background-color: rgba(255, 250, 236, 1);
            border-radius: 16 * 0.75px;

            .description {
              color: rgba(161, 91, 40, 1);
              font-size: 12 * 0.75px;
              line-height: 18 * 0.75px;
              @include clamp(2);
            }
          }
        }
        .core-item + .core-item {
        }
      }
    }
  }
  .essence {
    border-radius: 16 * 0.75px;
    width: calc(100% - 18 * 0.75px);

    .essence-box {
      background-color: rgba(115, 63, 18, 1);
      border-radius: 16 * 0.75px;
      .title {
        padding: 12 * 0.75px;
        border-radius: 16 * 0.75px 16 * 0.75px 0 0;
        .left {
          height: 21 * 0.75px;
          .icon {
            height: 21 * 0.75px;
            width: 21 * 0.75px;
            margin-right: 6 * 0.75px;
          }
          color: rgba(255, 237, 211, 1);
          line-height: 21 * 0.75px;
          font-size: 14 * 0.75px;
          font-weight: bold;
        }
        .right {
          .icon {
            margin-right: 4 * 0.75px;
            height: 12 * 0.75px;
            width: 12 * 0.75px;
          }
          height: 25 * 0.75px;
          background-color: rgba(255, 237, 211, 1);
          color: rgba(115, 63, 18, 1);
          font-size: 11 * 0.75px;
          padding: 0 6 * 0.75px;
          border-radius: 6 * 0.75px;
        }
      }
      .content {
        background-color: #fff;
        border-radius: 16 * 0.75px;
        overflow: hidden;
        margin-bottom: 6px;
      }
    }
  }
  .member-list {
    padding: 12 * 0.75px;
    min-width: 240px;
    .member-box {
      height: 48 * 0.75px;

      .avatar {
        border-radius: 999em;
        height: 48 * 0.75px;
        width: 48 * 0.75px;
        margin-right: 12 * 0.75px;
      }
      .name {
        line-height: 21 * 0.75px;
        font-weight: bold;
        margin-right: 4 * 0.75px;
        font-size: 14 * 0.75px;
      }
      .position {
        color: rgba(179, 179, 179, 1);
        font-size: 12 * 0.75px;
        line-height: 18 * 0.75px;
      }
      .reputation {
        width: fit-content;
        border: 0.5px solid rgba(218, 156, 0, 0.1);
        background-color: rgba(255, 250, 235, 1);
        border-radius: 4px;
        height: 24 * 0.75px;

        .icon-box {
          background: linear-gradient(
            204deg,
            rgba(250, 179, 0, 0.1) 5.96%,
            rgba(255, 206, 81, 0.1) 105.7%
          );
          .icon {
            width: 16 * 0.75px;
            height: 16 * 0.75px;
          }
        }
        .text {
          color: rgba(218, 156, 0, 1);
          font-size: 12 * 0.75px;
          padding: 4 * 0.75px;
        }
      }
    }
    .member-box + .member-box {
      margin-top: 16 * 0.75px;
    }
  }
  .activities {
    padding: 12 * 0.75px;
    .tab-list {
      display: flex;
      height: 40 * 0.75px;
      .tab-box {
        min-width: fit-content;
        height: 40 * 0.75px;
        padding: 10 * 0.75px 14 * 0.75px;
        background-color: #fffcf3;
        border: 1px solid rgba(252, 244, 222, 0.5);
        border-radius: 14 * 0.75px;
        font-size: 13 * 0.75px;
        color: rgba(115, 63, 18, 1);
        &.active {
          background-color: rgba(86, 194, 255, 0.2);
          color: rgba(35, 176, 255, 1);
          border-color: rgba(86, 194, 255, 0.2);
          font-weight: bold;
        }
      }
      .tab-box + .tab-box {
        margin-left: 8 * 0.75px;
      }
    }
    .history-activities {
      margin-top: 12 * 0.75px;
      .history-activities-photos {
        margin-bottom: 8 * 0.75px;
        .photos-cover {
          width: 200px;
          height: 190 * 0.75px;
          margin-right: 8 * 0.75px;
          border-radius: 12 * 0.75px;
        }
        .photos-list {
          .photos-item {
            width: 67px;
            height: 59 * 0.75px;
            border-radius: 8 * 0.75px;
            position: relative;
            overflow: hidden;
            img {
              width: 100%;
              height: 100%;
            }
            .photo-counts {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.6);
              color: #fff;
              font-size: 18 * 0.75px;
              top: 0;
            }
          }
          .photos-item + .photos-item {
            margin-top: 7 * 0.75px;
          }
        }
      }
      .history-activities-name {
        font-size: 16 * 0.75px;
        height: 24 * 0.75px;
        margin-bottom: 8 * 0.75px;
        font-weight: bold;
        img {
          width: 18 * 0.75px;
          height: 18 * 0.75px;
        }
      }
      .history-activities-users-counts,
      .history-activities-type {
        .icon {
          width: 16 * 0.75px;
          height: 16 * 0.75px;
          margin-right: 2 * 0.75px;
        }
        display: inline-flex;
        padding: 3 * 0.75px 5 * 0.75px;
        justify-content: center;
        align-items: center;
        border-radius: 6 * 0.75px;
        border: 0.5px solid rgba(255, 187, 14, 0.1);
        background: rgba(255, 187, 14, 0.1);
        color: rgba(242, 178, 15, 1);
        font-size: 12 * 0.75px;
      }
      .history-activities-type {
        border: 0.5px solid rgba(255, 141, 77, 0.1);
        background: rgba(255, 141, 77, 0.1);
        color: rgba(255, 141, 77, 1);
      }
      .history-activities-address {
        height: 20 * 0.75px;
        font-size: 13 * 0.75px;
        color: rgba(179, 179, 179, 1);
        img {
          width: 12 * 0.75px;
          height: 12 * 0.75px;
          margin-right: 5 * 0.75px;
        }
      }
    }
  }
  .cyc-circles {
    padding: 0px;
    .cyc-circles-box {
      padding: 12 * 0.75px;
      .avatar {
        border-radius: 999em;
        height: 36 * 0.75px;
        width: 36 * 0.75px;
        margin-right: 12 * 0.75px;
      }
      .name {
        line-height: 21 * 0.75px;
        font-weight: bold;
        margin-right: 8 * 0.75px;
        font-size: 14 * 0.75px;
      }
      .reputation {
        width: fit-content;
        border: 0.5px solid rgba(218, 156, 0, 0.1);
        background-color: rgba(255, 250, 235, 1);
        border-radius: 4px;
        height: 24 * 0.75px;
        .icon-box {
          background: linear-gradient(
            204deg,
            rgba(250, 179, 0, 0.1) 5.96%,
            rgba(255, 206, 81, 0.1) 105.7%
          );
          .icon {
            width: 16 * 0.75px;
            height: 16 * 0.75px;
          }
        }
        .text {
          color: rgba(218, 156, 0, 1);
          font-size: 12 * 0.75px;
          padding: 4 * 0.75px;
        }
      }
      .content-text {
        font-size: 15 * 0.75px;
        line-height: 22.5 * 0.75px;
        @include clamp(2);
        margin-top: 12 * 0.75px;
      }
      .res-list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 8 * 0.75px;
          gap: 4 * 0.75px;
          .res-img {
            width: 106 * 0.75px;
            height: 106 * 0.75px;
            border-radius: 4 * 0.75px;
          }
        }
    }
    .cyc-circles-box + .cyc-circles-box {
      border-top: 1px #eff2f6 solid;
    }
  }
}
</style>
