<template>
  <div style="" v-if="config && config.enable">
    <div class="requirement">
      <div class="requirement-content">
        <div class="flex">
          <div class="title">
            <div class="txet">商会加入要求</div>
            <img class="icon" src="../../assets/images/title-icon3.png"/>
          </div>
        </div>
        <div style="font-size: 12px;"  v-html="config.cyc_content"></div>
      </div>
      <div class="requirement-file">
        <div class="flex">
          <div class="title">
            <div class="txet">商会加入要求</div>
            <img class="icon" src="../../assets/images/title-icon3.png"/>
          </div>
        </div>
        <div v-for="(item, index) in config.file_urls" :key="index" class="requirement-file-urls">
          <DownloadItem :name="item.name" :url="item.url" />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import DownloadItem from './download-item.vue'
export default {
  components: { DownloadItem },
  props: ['config'],
}
</script>

<style lang="scss" scoped>
.requirement {
  padding: 0 16 * 0.75px;
  background-color: #f8f8f8;
  .title {
    position: relative;
    font-weight: bold;
    font-size: 16 * 0.75px;
    margin-bottom: 12 * 0.75px;
    .txet {
      position: relative;
      z-index: 1;
    }
    .icon {
      position: absolute;
      width: 12 * 0.75px;
      height: 12 * 0.75px;
      top: 0px;
      right: -14 * 0.75px;
    }
  }
  .requirement-content {
    background-color: #fff;
    padding: 12 * 0.75px;
    border-radius: 16 * 0.75px;
    ::v-deep img{
      max-width: 100% !important;
      height: auto; 
    }
  }
  .requirement-file {
    background-color: #fff;
    padding: 12 * 0.75px;
    border-radius: 16 * 0.75px;
  }
  .requirement-content + .requirement-file {
    margin-top: 12 * 0.75px;
  }
}
</style>
