<template>
  <div>
    <div
      style="background-color: #f8f8f8; height: calc(150px)"
    ></div>
    <div class="initiation-popop template1">
      <img class="bg" src="../../assets/images/initiation-popop-bg.png" />

      <div class="initiation-popop-content">
        <template>
          <div class="title">
            <div>“{{ info.title }}”</div>
            <img
              class="icon"
              src="../../assets/images/initiation-popop-icon.png"
            />
          </div>
          <div class="user-info-template1 flex-align-center">
            <el-image
              style="width: 46px; height: 46px; border-radius: 999em"
                  :src="info.active"
                  fit="cover"
                ></el-image>
            <div style="margin-left: 9px">
              <div class="user-info-name flex-align-center">
                <div class="text">
                  <div>{{ info.name }}</div>

                  <div class="line"></div>
                </div>
                <div>邀请你加入</div>
              </div>
              <div class="user-info-title">{{ titleName }}</div>
            </div>
          </div>
        </template>
        <!-- <template v-else>
          <div class="user-info-template2 flex-align-center">
            <div class="flex-align-center flex-1">
              <base-avatar :url="info.active" :size="36" />
              <div style="margin-left: 9px">
                <div class="user-info-name">{{ info.name }} 邀请你加入</div>
              </div>
            </div>
            <div @tap="toJodCost" class="guid-title-btn flex-align-center">
              <div class="text">职务会费</div>
              <div class="right-arrow flex-center">
                <uni-icons
                  style="transform: scale(0.8)"
                  type="right"
                  :size="12"
                  color="#fff"
                ></uni-icons>
              </div>
            </div>
          </div>
        </template> -->
        <div class="btn">申请入会</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['titleName'],
  data() {
    return {
      info: {
        active:  window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png", // 默认头像',
        name: '正式会员',
        title: '发掘实力，拓展人脉，共赢未来。',
      },
      isShow: true,
    }
  },

  computed: {
    // 淡一些的主题色

    themeAlphaColor() {
      return this.olorRgb('#3576ff', '.1')
    },
  },
  mounted() {},
  methods: {
    toJodCost() {
      uni.navigateTo({
        url: '/sub/invitation/pages/job-cost',
      })
    },
    // 16进值转 rgba 可以设透明度
    olorRgb(color, alpha) {
      var reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
      var sColor = color.toLowerCase()
      if (sColor && reg.test(sColor)) {
        if (sColor.length === 4) {
          var sColorNew = '#'
          for (var i = 1; i < 4; i += 1) {
            sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
          }
          sColor = sColorNew
        }
        //处理六位的颜色值
        var sColorChange = []
        for (var i = 1; i < 7; i += 2) {
          sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
        }

        if (alpha) {
          return 'rgba(' + sColorChange.join(',') + `,${alpha}` + ')'
        } else {
          return 'rgba(' + sColorChange.join(',') + ')'
        }
      } else {
        // 本来就是 rgba
        if (alpha) {
          // 截取rgba 括号的内容
          let strArr = sColor.split(',')
          // 替换最后一个透明度值
          strArr[strArr.length - 1] = alpha + ')'
          return strArr.join(',')
        } else {
          return sColor
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.template1 {
  height: 200 * 0.75px;
  // box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.24), 0 0 24px 0 rgba(0, 0, 0, 0.12);
}
.template2 {
  overflow: hidden;
  height: 240 * 0.75px;
  // box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.24), 0 0 24px 0 rgba(0, 0, 0, 0.12);
}
.initiation-popop {
  position: absolute;
  top: calc(620px - 200 * 0.75px);
  z-index: 2;
  width: 315px;
  background-color: #fff;
  border-radius: 24 * 0.75px 24 * 0.75px 0 0;
  transition: all 0.5s;
  .bg {
    width: 100%;
    height: 198 * 0.75px;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 24 * 0.75px 24 * 0.75px 0 0;
    overflow: hidden;
  }
  .initiation-popop-content {
    position: relative;
    z-index: 1;
    padding: 16 * 0.75px;

    .title {
      color: #a15b28;
      font-size: 14 * 0.75px;
      margin-bottom: 30 * 0.75px;
      line-height: 21 * 0.75px;
      font-weight: bold;
      .icon {
        position: absolute;
        right: 8 * 0.75px;
        top: -16 * 0.75px;
        height: 92 * 0.75px;
        width: 92 * 0.75px;
      }
    }

    .user-info-template1 {
      margin-bottom: 15 * 0.75px;
      padding-left: 3 * 0.75px;
      .user-info-name {
        font-size: 19 * 0.75px;
        font-weight: bold;
        margin-bottom: 7 * 0.75px;
        line-height: 28.5 * 0.75px;

        .text {
          position: relative;
          z-index: 2;
          .line {
            position: absolute;
            z-index: -1;
            content: '';
            left: 0;
            width: 100%;
            height: 6 * 0.75px;
            bottom: 3 * 0.75px;
            background-color: rgba(255, 249, 108, 1);
          }
        }
      }
      .user-info-title {
        font-size: 12 * 0.75px;
        color: #6a3210;
        height: 18 * 0.75px;
        padding: 0 4 * 0.75px;
        line-height: 18 * 0.75px;
        background-color: #fae8d7;
        display: inline-block;
        border-radius: 3 * 0.75px;
      }
    }
    .user-info-template2 {
      margin-bottom: 12 * 0.75px;
      justify-content: space-between;
      .user-info-name {
        font-size: 16 * 0.75px;
        font-weight: bold;
      }
      .guid-title-btn {
        width: 80 * 0.75px;
        height: 30 * 0.75px;
        padding: 0 8 * 0.75px;
        border-radius: 999em;
        background: #3576ff;
        color: #fff;
        font-size: 12 * 0.75px;
        .right-arrow {
          width: 12 * 0.75px;
          height: 12 * 0.75px;
          border-radius: 999em;
          background-color: #3576ff;
          margin-left: 4 * 0.75px;
        }
      }
    }
    .btn {
      display: flex;
      height: 48 * 0.75px;
      justify-content: center;
      align-items: center;
      border-radius: 999em;
      background: #3576ff;
      color: #fff;
      font-size: 16 * 0.75px;
    }
  }
}
</style>
