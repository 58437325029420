<template>
  <div class="cycCircle" v-if="config && config.enable">
    <div class="contents" v-if="config.contents">
      <div class="cyc-circles">
        <div
          class="cyc-circles-box"
          v-for="item in config.contents"
          :key="item.id"
        >
          <div class="flex">
            <img style="object-fit: cover" class="avatar" :src="item.avatar" />
            <div class="flex-align-center">
              <div class="flex-align-center">
                <div class="name flex-1">{{ item.nickname }}</div>
                <div
                  v-if="item.title_name"
                  class="reputation flex-align-center"
                >
                  <div class="icon-box">
                    <img
                      class="icon"
                      src="../../assets/images/reputation-icon.png"
                    />
                  </div>
                  <div class="text">{{ item.title_name }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="content-text">{{ item.content_text }}</div>
          <template v-if="item.res && item.res.length">
            <div class="res-list">
              <img
                v-for="img in item.res"
                style="object-fit: cover"
                :key="img.url"
                :src="img.url"
                class="res-img"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['config'],
  data() {
    return {
      resUrl: window.serverConfig.VUE_APP_IMG_URL,
    }
  },
}
</script>
<style lang="scss" scoped>
.cycCircle {
  background-color: #f8f8f8;
  .title {
    position: relative;
    font-weight: bold;
    font-size: 16 * 0.75px;
    margin-bottom: 12 * 0.75px;
    .txet {
      position: relative;
      z-index: 1;
    }
    .icon {
      position: absolute;
      width: 12 * 0.75px;
      height: 12 * 0.75px;
      top: 0px;
      right: -14 * 0.75px;
    }
  }

  .contents {
    // background-color: #fff;
    padding: 0 16 * 0.75px;
    // border-radius: 16 * 0.75px;
    .cyc-circles {
      padding: 0px;
      .cyc-circles-box {
        padding: 12 * 0.75px;
        background-color: #fff;
        border-radius: 16 * 0.75px;
        .avatar {
          border-radius: 999em;
          height: 36 * 0.75px;
          width: 36 * 0.75px;
          margin-right: 12 * 0.75px;
        }
        .name {
          line-height: 21 * 0.75px;
          font-weight: bold;
          margin-right: 8 * 0.75px;
          font-size: 14 * 0.75px;
        }
        .reputation {
          width: fit-content;
          border: 0.5px solid rgba(218, 156, 0, 0.1);
          background-color: rgba(255, 250, 235, 1);
          border-radius: 4px;
          height: 24 * 0.75px;
          .icon-box {
            background: linear-gradient(
              204deg,
              rgba(250, 179, 0, 0.1) 5.96%,
              rgba(255, 206, 81, 0.1) 105.7%
            );
            .icon {
              width: 16 * 0.75px;
              height: 16 * 0.75px;
            }
          }
          .text {
            color: rgba(218, 156, 0, 1);
            font-size: 12 * 0.75px;
            padding: 4 * 0.75px;
          }
        }
        .content-text {
          font-size: 15 * 0.75px;
          line-height: 22.5 * 0.75px;
          @include clamp(2);
          margin-top: 12 * 0.75px;
        }
        .res-list {
          display: flex;
          flex-wrap: wrap;
          margin-top: 8 * 0.75px;
          gap: 4 * 0.75px;
          .res-img {
            width: 106 * 0.75px;
            height: 106 * 0.75px;
            border-radius: 4 * 0.75px;
          }
        }
      }
      .cyc-circles-box + .cyc-circles-box {
        margin-top: 12 * 0.75px;
      }
    }
  }
  .activities + .contents {
    margin-top: 12 * 0.75px;
  }
}
</style>
