<template>
  <div v-if="config && config.enable" class="other-members">
    <img src="../../assets/images/other-members-icon.png" />
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
  },
}
</script>

<style lang="scss" scoped>
.other-members {
  padding: 12px;
  img {
    width: 100%;
  }
}
</style>
