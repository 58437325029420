<template>
  <div class="cycCircle" v-if="config && config.enable">
    <div
      class="activities"
      v-if="config.next_activities.length || config.history_activities.length"
    >
      <template v-for="item in config.next_activities">
        <div :key="item.id" class="next-activities">
          <div
            class="next-activitie-title"
            :style="{
              backgroundImage: `url(${resUrl}/invitation/next-activities-bg.png)`,
            }"
          >
            <div class="title1">组织最近活动</div>
            <div class="title2">加入组织参加下场活动</div>
          </div>
          <div class="next-activitie-content">
            <div class="cover">
              <img :src="item.cover" style="object-fit: cover" class="image" />
              <div class="info flex-align-center">
                <div class="category">
                  <img
                    class="icon"
                    src="../../assets/images/categories-icon.png"
                  />
                  {{ item.category_name }}
                </div>
                <div class="name">
                  {{ item.name }}
                </div>
              </div>
            </div>
            <div v-if="item.guest" class="user-info flex-align-center">
              <img
                src="../../assets/images/user-info-right-icon.png"
                class="icon"
              />
              <img
                class="avatar"
                :src="item.guest.avatar"
                style="object-fit: cover"
              />
              <div>
                <div class="name">
                  {{ item.guest.name }}
                </div>
                <div class="description">
                  {{ item.guest.description }}
                </div>
              </div>
            </div>
            <div class="time flex-align-center">
              <div class="flex-1 flex-align-center">
                <img class="icon" src="../../assets/images/time-icon.png" />
                <div class="text">{{ item.time_text }}</div>
              </div>
              <div class="time-btn flex-center">去参加</div>
            </div>
          </div>
        </div>
      </template>
      <template v-for="item in config.history_activities">
        <div :key="item.id" class="history-activities">
          <div class="history-activities-photos flex" v-if="item.photos.length">
            <img
              style="object-fit: cover"
              class="photos-cover flex-1"
              :src="item.photos[0]"
            />

            <div class="photos-list">
              <div
                v-for="(image, index) in item.photos.slice(1, 4)"
                :key="index"
                class="photos-item"
              >
                <img style="object-fit: cover" class="image" :src="image" />
                <template v-if="index === 2 && item.photo_count > 4">
                  <div class="photo-counts flex-center">
                    +{{ item.photo_count }}
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="flex-align-center history-activities-name">
            {{ item.name }}
          </div>
          <div class="history-activities-address">
            {{ item.address_name }}
          </div>
          <div class="flex">
            <div class="history-activities-type">
              <img class="icon" src="../../assets/images/categories-icon.png" />
              {{ item.categories }}
            </div>
            <div
              style="margin-left: 4px"
              class="history-activities-users-counts"
            >
              <img
                class="icon"
                src="../../assets/images/users-counts-icon.png"
              />
              {{ item.activity_users_count }}人参会
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: ['config'],
  data() {
    return {
      resUrl: window.serverConfig.VUE_APP_IMG_URL,
    }
  },
}
</script>
<style lang="scss" scoped>
.cycCircle {
  background-color: #f8f8f8;
  .title {
    position: relative;
    font-weight: bold;
    font-size: 16 * 0.75px;
    margin-bottom: 12 * 0.75px;
    .txet {
      position: relative;
      z-index: 1;
    }
    .icon {
      position: absolute;
      width: 12 * 0.75px;
      height: 12 * 0.75px;
      top: 0px;
      right: -14 * 0.75px;
    }
  }
  .activities {
    padding: 0 16 * 0.75px;
    .next-activities {
      .next-activitie-title {
        height: 79 * 0.75px;
        padding: 17 * 0.75px 12 * 0.75px;
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        margin: -12 * 0.75px 0;
        .title1 {
          color: #047ebe;
          font-size: 16 * 0.75px;
          line-height: 24 * 0.75px;
          font-weight: bold;
        }
        .title2 {
          line-height: 18 * 0.75px;
          color: #047ebe;
          font-size: 12 * 0.75px;
        }
      }
      .next-activitie-content {
        padding: 12 * 0.75px;
        background-color: #fff;
        border-radius: 12 * 0.75px;
        .cover {
          position: relative;
          height: 171 * 0.75px;
          border-radius: 8 * 0.75px;
          overflow: hidden;
          .image {
            width: 100%;
            height: 100%;
          }
          &::after {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            background: linear-gradient(
              0deg,
              rgba(0, 0, 0, 0.6) 0%,
              rgba(0, 0, 0, 0) 100%
            );
            height: 50%;
            width: 100%;
            z-index: 1;
          }
          .info {
            position: absolute;
            left: 8 * 0.75px;
            bottom: 8 * 0.75px;
            z-index: 2;
            .category {
              .icon {
                width: 16 * 0.75px;
                height: 16 * 0.75px;
                margin-right: 2 * 0.75px;
              }
              display: inline-flex;
              padding: 3 * 0.75px 5 * 0.75px;
              justify-content: center;
              align-items: center;
              border-radius: 6 * 0.75px;
              font-size: 12 * 0.75px;
              border: 0.5px solid rgba(255, 141, 77, 0.1);
              background: linear-gradient(
                  0deg,
                  rgba(255, 141, 77, 0.1) 0%,
                  rgba(255, 141, 77, 0.1) 100%
                ),
                #fff;
              color: rgba(255, 141, 77, 1);
              margin-right: 8 * 0.75px;
            }
            .name {
              height: 24 * 0.75px;
              line-height: 24 * 0.75px;
              color: #ffffff;
              font-size: 16 * 0.75px;
              font-weight: bold;
              @include clamp(1);
            }
          }
        }
        .user-info {
          height: 64 * 0.75px;
          padding: 8 * 0.75px;
          gap: 9 * 0.75px;
          position: relative;
          margin-top: 10 * 0.75px;
          border: 1px solid #eff2f6;
          background-color: rgba(248, 249, 251, 1);
          border-radius: 8 * 0.75px;
          .avatar {
            height: 44 * 0.75px;
            width: 44 * 0.75px;
            border-radius: 999rem;
          }
          .icon {
            position: absolute;
            height: 34 * 0.75px;
            width: 34 * 0.75px;
            right: 5 * 0.75px;
            top: 1 * 0.75px;
          }
          .name {
            margin-bottom: 4 * 0.75px;
            line-height: 21 * 0.75px;
            font-size: 14 * 0.75px;
            @include clamp(1);
            font-weight: bold;
          }
          .description {
            font-size: 12 * 0.75px;
            line-height: 18 * 0.75px;
            color: rgba(128, 128, 128, 1);
            @include clamp(1);
          }
        }
        .time {
          margin-top: 10 * 0.75px;
          .icon {
            height: 24 * 0.75px;
            width: 24 * 0.75px;
          }
          .text {
            margin-left: 8 * 0.75px;
            color: rgba(218, 156, 0, 1);
            font-size: 14 * 0.75px;
            line-height: 21 * 0.75px;
          }

          .time-btn {
            background-color: rgba(117, 83, 46, 1);
            border-radius: 39 * 0.75px;
            height: 32 * 0.75px;
            padding: 0 13 * 0.75px;
            color: #fff;
            font-size: 11 * 0.75px;
            font-weight: bold;
          }
        }
      }
    }
    .history-activities {
      background-color: #fff;
      padding: 12 * 0.75px;
      border-radius: 16 * 0.75px;

      .history-activities-photos {
        margin-bottom: 8 * 0.75px;
        gap: 8 * 0.75px;
        .photos-cover {
          height: 190 * 0.75px;
          border-radius: 12 * 0.75px;
        }
        .photos-list {
          .photos-item {
            width: 79 * 0.75px;
            height: 59 * 0.75px;
            border-radius: 8 * 0.75px;
            position: relative;
            overflow: hidden;
            .image {
              width: 100%;
              height: 100%;
            }
            .photo-counts {
              position: absolute;
              width: 100%;
              height: 100%;
              background-color: rgba(0, 0, 0, 0.6);
              color: #fff;
              top: 0;
            }
          }
          .photos-item + .photos-item {
            margin-top: 7 * 0.75px;
          }
        }
      }
      .history-activities-name {
        font-size: 16 * 0.75px;
        height: 24 * 0.75px;
        margin-bottom: 4 * 0.75px;
        font-weight: bold;
        @include clamp(1);
      }
      .history-activities-users-counts,
      .history-activities-type {
        .icon {
          width: 16 * 0.75px;
          height: 16 * 0.75px;
          margin-right: 2 * 0.75px;
        }
        display: inline-flex;
        padding: 3 * 0.75px 5 * 0.75px;
        justify-content: center;
        align-items: center;
        border-radius: 6 * 0.75px;
        border: 0.5px solid rgba(255, 187, 14, 0.1);
        background: rgba(255, 187, 14, 0.1);
        color: rgba(242, 178, 15, 1);
        font-size: 12 * 0.75px;
      }
      .history-activities-type {
        border: 0.5px solid rgba(255, 141, 77, 0.1);
        background: rgba(255, 141, 77, 0.1);
        color: rgba(255, 141, 77, 1);
      }
      .history-activities-address {
        height: 20 * 0.75px;
        font-size: 13 * 0.75px;
        color: rgba(128, 128, 128, 1);
        margin-bottom: 4px;
        @include clamp(1);
      }
    }
    .next-activities + .history-activities,
    .history-activities + .history-activities,
    .next-activities + .next-activities {
      margin-top: 12 * 0.75px;
    }
  }
  .activities + .contents {
    margin-top: 12 * 0.75px;
  }
}
</style>
