<template>
  <div class="guide-popup" v-if="config && config.enable">
    <div class="content">
      <div class="bottom">
        <Guide :tags="tags" />
        <div class="btn flex-center">申请入会</div>
      </div>
    </div>
  </div>
</template>

<script>
import Guide from './Guide.vue'
export default {
  components: { Guide },
  props: ['config'],
  computed: {
    tags() {
      return this.config && this.config.enable && this.config.tags.length
        ? this.config.tags.filter((t) => t.is_show).map((t) => t.name)
        : []
    },
  },
}
</script>

<style lang="scss" scoped>
.guide-popup {
  position: absolute;
  height:calc(100% - 40px);
  width: calc(100% - 40px);
  background-color: rgba(0, 0, 0, 0.5);
  top: 20px;
  z-index: 999;
  border-radius: 12px;
  .content {
    height: 70%;
    background-color: #fff;
    position: absolute;
    bottom: 0px;
    border-radius: 10px 10px 0 0;
    width: 100%;
    .bottom {
      position: absolute;
      bottom: 0px;
      width: 100%;
      .btn {
        background-color: #3576ff;
        width: 275px;
        margin: 15 * 0.75px auto;
        height: 48 * 0.75px;
        color: #fff;
        font-size: 16 * 0.75px;
        border-radius: 999em;
      }
    }
  }
}
</style>
