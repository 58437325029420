<template>
  <div class="content">
    <RouterTabs
      v-model="activeName"
      :tabArr="tabs"
      :queryData="query"
      :max="9"
    />
    <div class="flex">
      <div class="phone-border">
        <div class="phone-radius">
          <div ref="scrollContainer" class="phone-model">
            <div
              :ref="t.name"
              v-for="t in tabs"
              :key="t.name"
              @click="
                t.name === activeName ? '' : $router.push({ name: t.name })
              "
            >
              <Base v-if="t.key === 'base'" :config="previewForm[t.key]" />
              <template v-else-if="t.key === 'members'">
                <Members
                  :bgColor="previewForm.base.background_color"
                  :config="previewForm[t.key]"
                />
                <div
                  v-if="getNavList.length"
                  :style="{ background: 'rgba(255, 241, 207, 1)' }"
                >
                  <div class="tabs-list flex">
                    <div
                      class="flex"
                      style="position: absolute; min-width: fit-content"
                    >
                      <div
                        class="tabs-item"
                        :class="[item.name === nav ? 'active' : '']"
                        v-for="item in getNavList"
                        :key="item.name"
                        @click.stop="
                          item.name === activeName
                            ? ''
                            : $router.push({ name: item.name })
                        "
                      >
                        {{ form[item.key].alias || item.label }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <OtherMembers
                v-else-if="t.key === 'other_members' && nav === t.name"
                :config="previewForm[t.key]"
              />
              <CycCrcle
                v-else-if="t.key === 'cyc_circle' && nav === t.name"
                :config="previewForm[t.key]"
              />
              <Activity
                v-else-if="t.key === 'activity' && nav === t.name"
                :config="previewForm[t.key]"
              />
              <Honor
                v-else-if="t.key === 'honor' && nav === t.name"
                :config="previewForm[t.key]"
              />
              <Requirement
                v-else-if="t.key === 'requirement' && nav === t.name"
                :config="previewForm[t.key]"
              />
              <Rights
                v-else-if="t.key === 'rights' && nav === t.name"
                :config="previewForm[t.key]"
              />
              <GuidePopup
                v-else-if="t.key === 'guide' && GuidePopupShow"
                :config="previewForm[t.key]"
              />
            </div>
            <InvitationPopup :titleName="previewForm.base.name" />
          </div>
        </div>
      </div>
      <div class="flex-1">
        <router-view
          v-model="previewForm"
          :formData="form"
          @saveConfig="saveConfig"
          @openCode="showCode = true"
        />
      </div>
    </div>
    <Config :showCode="showCode" @closeCode="showCode = false" />
  </div>
</template>

<script>
import RouterTabs from '@/base/components/Default/RouterTabs.vue'
import { getConfig, setConfig } from '../../api/CycIntroducePage'
import Members from '../../components/CycIntroducePage/Members.vue'
import Base from '../../components/CycIntroducePage/Base.vue'
import CycCrcle from '../../components/CycIntroducePage/CycCrcle.vue'
import Honor from '../../components/CycIntroducePage/Honor.vue'
import Requirement from '../../components/CycIntroducePage/Requirement.vue'
import Rights from '../../components/CycIntroducePage/Rights.vue'
import Activity from '../../components/CycIntroducePage/Activity.vue'
import OtherMembers from '../../components/CycIntroducePage/OtherMembers.vue'
import GuidePopup from '../../components/CycIntroducePage/GuidePopup.vue'
import InvitationPopup from '../../components/CycIntroducePage/InvitationPopup.vue'
import Config from '../../components/CycIntroducePage/Config.vue'
export default {
  components: {
    RouterTabs,
    Base,
    Members,
    CycCrcle,
    Honor,
    Requirement,
    Rights,
    GuidePopup,
    InvitationPopup,
    Config,
    OtherMembers,
    Activity,
  },
  data() {
    return {
      activeName: 'CycIntroducePageBase', //当前选中tab
      query: {}, //路由的query参数
      form: {
        base: {},
        members: {},
        honor: {},
        requirement: {},
        rights: {},
        guide: {},
        cyc_circle: {},
        other_members: {},
        activity: {},
      }, // 路由的params参数
      loading: true,
      // tab配置
      tabs: [
        {
          label: '基础设置',
          name: 'CycIntroducePageBase',
          key: 'base',
        },
        {
          label: '核心成员',
          name: 'CycIntroducePageMembers',
          key: 'members',
        },
        {
          label: '商会会员',
          name: 'CycIntroducePageOtherMembers',
          key: 'other_members',
        },
        {
          label: '商会资源',
          name: 'CycIntroducePageCycCircle',
          key: 'cyc_circle',
        },
        {
          label: '商会活动',
          name: 'CycIntroducePageActivity',
          key: 'activity',
        },
        {
          label: '商会荣誉',
          name: 'CycIntroducePageHonor',
          key: 'honor',
        },
        {
          label: '加入要求',
          name: 'CycIntroducePageRequirement',
          key: 'requirement',
        },
        {
          label: '会员权益',
          name: 'CycIntroducePageRights',
          key: 'rights',
        },
        {
          label: '入会引导设置',
          name: 'CycIntroducePageGuide',
          key: 'guide',
        },
      ],
      key: 'base',
      nav: '',
      navList: [
        {
          label: '商会会员',
          name: 'CycIntroducePageOtherMembers',
          key: 'other_members',
        },
        {
          label: '商会资源',
          name: 'CycIntroducePageCycCircle',
          key: 'cyc_circle',
        },

        {
          label: '商会活动',
          name: 'CycIntroducePageActivity',
          key: 'activity',
        },
        {
          label: '商会荣誉',
          name: 'CycIntroducePageHonor',
          key: 'honor',
        },
        {
          label: '加入要求',
          name: 'CycIntroducePageRequirement',
          key: 'requirement',
        },
        {
          label: '会员权益',
          name: 'CycIntroducePageRights',
          key: 'rights',
        },
      ],
      previewForm: {
        base: {},
        members: {},
        honor: {},
        requirement: {},
        rights: {},
        guide: {},
        cyc_circle: {},
        other_members: {},
        activity: {},
      },
      GuidePopupShow: false,
      showCode: false,
    }
  },
  created() {
    this.activeName = this.$route.name
    this.tabs.map((item) => {
      getConfig({ key: item.key }).then((res) => {
        this.form[item.key] = res.data
        this.previewForm[item.key] = res.data
      })
    })
  },
  computed: {
    routeName() {
      return this.$route.name
    },
    getNavList() {
      let list = this.navList.filter((t) => {
        if (this.previewForm[t.key].enable) {
          return t
        }
      })
      if (!this.nav && list.length) {
        this.nav = list[0].name
      }
      return list
    },
    getTags() {
      return this.tabs.filter((item) => {
        if (this.previewForm[item.key].enable !== 0) {
          return item
        }
      })
    },
  },
  watch: {
    routeName: {
      handler(val) {
        this.activeName = val
        let top = 0
        if (this.navList.some((t) => t.name === val)) {
          this.nav = val
          top = -33
        }
        if (val === 'CycIntroducePageGuide') {
          this.GuidePopupShow = true
          return
        } else {
          this.GuidePopupShow = false
        }
        this.$nextTick(() => {
          const target = this.$refs[val][0]
          const container = this.$refs.scrollContainer
          if (container && target) {
            const scrollOffset = target.offsetTop - container.offsetTop + top
            container.scrollTo({
              top: scrollOffset,
              behavior: 'smooth',
            })
          }
        })
      },
      immediate: true,
    },
  },
  methods: {
    saveConfig(form) {
      setConfig(form).then(({msg}) => {
        this.$message.success(msg)
        getConfig({ key: form.key }).then((res) => {
          this.form[form.key] = res.data
          this.previewForm[form.key] = res.data
        })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  .phone-border {
    position: relative;
    margin-right: 20px;
    padding: 20px;
    border-radius: 24px;
    height: fit-content;
    overflow: hidden;
    border: 1px dashed #dadfe6;
    background: #f8f9fb;
    .phone-radius {
      overflow: hidden;
      border-radius: 12px;
    }
    .phone-model {
      background: #fff;
      &::-webkit-scrollbar {
        display: none; /* Chrome Safari */
      }
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: auto;
      width: 315px;
      height: 600px;
      border-radius: 12px;
    }
  }
  .tabs-list {
    height: 42px;
    padding: 12 * 0.75px;
    background-color: #f8f8f8;
    border-radius: 12px 12px 0 0;
    position: relative;
    overflow: hidden;
    .tabs-item {
      // width: 25%;
      color: #b3b3b3;
      font-size: 16 * 0.75px;
      line-height: 24 * 0.75px;
      padding-bottom: 8 * 0.75px;
      text-align: center;
      min-width: fit-content;
      &.active {
        color: #1a1a1a;
        font-weight: bold;
        position: relative;
        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          border-radius: 999em;

          background-color: #dd193e;
          transform: translateX(-50%);
          width: 16 * 0.75px;
          height: 5 * 0.75px;
        }
      }
    }
    .tabs-item + .tabs-item {
      margin-left: 24 * 0.75px;
    }
  }
}
</style>
