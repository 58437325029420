<template>
  <div class="base">
    <div style="margin-bottom: -60px; width: 100%; height: 224px">
      <img
        style="width: 100%; height: 210px; object-fit: cover"
        :src="config.background"
      />
    </div>
    <div class="top-box">
      <div class="bg-color-image" :style="{      background: `linear-gradient(180deg, transparent 0%, ${config.background_color} 100%)`}">
        <!-- <img
          class="image"
          :style="{
            filter: `drop-shadow(0px -53px 0px ${config.background_color})`,
          }"
          src="../../assets/images/bg-color.png"
        /> -->
      </div>
      <div
        class="bg-color"
        :style="{
          backgroundColor: config.background_color,
        }"
      ></div>
      <div
        class="info flex-col flex-align-center"
        :style="{ color: textColor }"
      >
        <div class="logo-box flex-center">
          <img style="object-fit: cover" class="logo" :src="config.logo" />
          <img class="logo-bg" src="../../assets/images/logo-bg.png" />
        </div>
        <div class="name">
          {{ config.name }}
        </div>
        <div v-if="config.tags_enable" class="tags">
          <div class="tag-box" v-for="item in config.tags" :key="item">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: Object,
  },
  computed: {
    textColor() {
      return this.$props.config
        ? this.judgeTextColor(this.$props.config.background_color)
        : '#fff'
    },
  },
  methods: {
    judgeTextColor(primaryColor) {
      if (primaryColor) {
        let rgb = this.hexToRgb(primaryColor)
        let num = rgb.r * 0.2126 + rgb.g * 0.7152 + rgb.b * 0.0722
        console.log(num, 'num')

        if (num >= 128) {
          return '#000'
        } else {
          return '#fff'
        }
      } else {
        return '#000'
      }
    },
    hexToRgb(hex) {
      let r = parseInt(hex.substring(1, 3), 16)
      let g = parseInt(hex.substring(3, 5), 16)
      let b = parseInt(hex.substring(5, 7), 16)
      return { r, g, b }
    },
  },
}
</script>
<style lang="scss" scoped>
.base {
  .top-box {
    height: 115px;
    width: 100%;
    position: relative;
    .bg-color-image {
      height: 236*0.75px;
      width: 100%;
      // overflow: hidden;
      position: absolute;
      top: -200*0.75px;
      left: 0;

      //   z-index: -1;
      // .image {
      //   height: 53px;
      //   width: 300px;
      //   transform: translateY(53px) translateZ(0);
      //   position: relative;
      //   ::after {
      //     position: absolute;
      //     width: 100%;
      //     height: 100%;
      //   }
      // }
    }
    .bg-color {
      position: absolute;
      height: 88px;
      width: 100%;
      bottom: 0px;
    }
    .info {
      position: relative;
      z-index: 2;
      .logo-box {
        position: relative;
        transform: translateY(-6px);
        .logo {
          width: 64px;
          height: 64px;
          background-color: #fff;
          border-radius: 12px;
          transform: translateY(-5px);
        }
        .logo-bg {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          width: 126px;
          height: 64px;
        }
      }
      .name {
        // color: #fff;
        font-size: 14px;
        margin-bottom: 8px;
        font-weight: bold;
      }
      .tags {
        display: flex;
        margin-bottom: 8px;
        .tag-box {
          // color: #fff;
          background-color: rgba(255, 255, 255, 0.2) ;
          border-radius: 4px;
          display: flex;
          padding: 1px 3px;
          justify-content: center;
          align-items: center;
          margin-right: 4px;
          font-size: 9px;
        }
      }
    }
  }
}
</style>
