<template>
  <div  v-if="config && config.enable">
    <div class="honor">
      <div v-for="(item, index) in config.honours" :key="index" class="honours">
        <img
          style="object-fit: cover"
          class="honours-image"
          :src="item.image"
        />
        <div class="honours-name">
          <div class="honours-name-text">{{ item.name }}</div>
          <img
            class="honours-name-image"
            src="../../assets/images/honours-name-icon.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['config'],
}
</script>

<style lang="scss" scoped>
.honor {
  background-color: #f8f8f8;
  padding: 0 16 * 0.75px;
  border-radius: 16 * 0.75px;
  .honours {
    border-radius: 12 * 0.75px;
    border: 1px solid rgba(233, 182, 54, 0.36);
    background: linear-gradient(
        180deg,
        rgba(233, 182, 54, 0.04) 0%,
        rgba(231, 172, 22, 0.08) 100%
      ),
      #fff;
    .honours-image {
      border-radius: 12px 12px 0 0;
      height: 253 * 0.75px;
      width: 100%;
    }
    .honours-name {
      //   height: 46 * 0.75px;
      line-height: 21 * 0.75px;
      padding: 12 * 0.75px;
      padding-right: 96 * 0.75px;
      color: rgba(165, 131, 9, 1);
      font-size: 14 * 0.75px;
      font-weight: bold;
      position: relative;
      .honours-name-image {
        height: 96 * 0.75px;
        width: 96 * 0.75px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  .honours + .honours {
    margin-top: 12 * 0.75px;
  }
}
</style>
