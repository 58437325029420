<template>
  <div
    v-if="config && config.enable"
  >
    <div class="rights">
      <div class="rights-content">
        <div
        class="rights-content-text"
          v-html="config.cyc_content"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['config'],
}
</script>

<style lang="scss" scoped>
.rights {
  padding: 0 16 * 0.75px;
  background-color: #f8f8f8;
  .rights-content {
    background-color: #fff;
    padding: 12 * 0.75px;
    border-radius: 16 * 0.75px;
    font-size: 12px;
    ::v-deep img{
      max-width: 100% !important;
      height: auto;
    }
  }
}
</style>
