import api from '@/base/utils/request';

// 返回数据
export const getConfig = (data) => {
    return api({
        url: '/admin/cyc/settings/getCycIntroducePageConfig',
        method: 'post',
        data,
        notCancel:true
    });
};
// 数据保存
export const setConfig = data => {
    return api({
        url: '/admin/cyc/settings/setCycIntroducePageConfig',
        method: 'post',
        data
    });
};
export const getContent = (data) => {
    return api({
        url: '/admin/cyc_circle/content/index',
        method: 'post',
        data
    });
}
    
export const getActivity = (data) => {
    return api({
        url: '/admin/cyc_activity/activity/index',
        method: 'post',
        data
    });
}
    
export const updatePhotoActivityId = (data) => {
    return api({
        url: '/admin/cyc_activity/activity/updatePhotoActivityId',
        method: 'post',
        data
    });
}
export const getCycIntroducePageQrcode = (data) => {
    
    return api({
        url: '/admin/cyc/settings/getCycIntroducePageQrcode',
        method: 'post',
        data
    });
}