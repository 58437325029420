<template>
  <div class="download-item flex-between" >
    <template v-if="type === 'image'">
      <img class="prefix" src="../../assets/icons/file_image.png" mode="aspectFit"/>
    </template>
		<template v-else>
			<img v-if="fileName.indexOf('.xls') !== -1" class="prefix" src="../../assets/icons/file_excel.png" mode="aspectFit"/>
			<img v-else-if="fileName.indexOf('.pdf') !== -1" class="prefix" src="../../assets/icons/file_pdf.png" mode="aspectFit"/>
			<img v-else-if="fileName.indexOf('.doc') !== -1" class="prefix" src="../../assets/icons/file_word.png" mode="aspectFit"/>
		</template>
    <div class="clamp">
      <text>{{name}}</text>
    </div>

    <i class="el-icon-arrow-right" style="font-size:12px; color: rgb(168,169,170)"  ></i>
  </div>
</template>
<script>
export default {
  name: 'download-item',
  props: {
    type: {
      type: [Number, String],
      default: 'image'
    },
    name: {
      type: String
    },
    url: {
      type: String
    }
  },
  data() {
    return {
      filePath: ''
    }
  },
  computed: {
    fileName() {
      return this.name.toLowerCase()
    }
  },
  methods: {
  
  }
}
</script>
<style lang="scss">
  .download-item {
    background-color: #f9f9f9;
    border-radius: 6 * 0.75px;
    height: 42 * 0.75px;
    padding: 0 12 * 0.75px;
    width: 100%;

    &.flex-between {
      display: flex;
      align-items: center;
    }

    .prefix {
      width: 16 * 0.75px;
      height: 16 * 0.75px;
    }

    .clamp {
      flex: 1;
      min-width: 0;
      margin: 0 11 * 0.75px;
      font-size: 12 * 0.75px;
    }
  }
</style>
