<template>
  <div class="guide-box">
    <div class="guide">
      <div class="guide-title flex-align-center">
        <img src="../../assets/images/guid-title-icon.png" class="guid-title-icon" />
        <div class="guid-title-text">加入组织，解锁内容</div>
        <div  class="guid-title-btn flex-align-center">
          <div class="text">职务会费</div>
          <div class="right-arrow flex-center">
            <i class="el-icon-arrow-right" style="font-size:10px; transform: scale(0.8); color:rgba(161, 91, 40, 1)"  ></i>
          </div>
        </div>
      </div>
      <div class="tags-scroll" scroll-x>
        <div class="flex tags-list">
          <div class="tags-item flex-center" v-for="(item,index) in list1" :key="index">
            <img src="../../assets/images/tags-item-icon.png" class="tags-item-icon">
            <div class="tags-item-name">{{ item }}</div>
          </div>
        </div>
        <div class="flex tags-list">
          <div class="tags-item flex-center" v-for="(item,index)  in list2" :key="index">
            <img src="../../assets/images/tags-item-icon.png" class="tags-item-icon">
            <div class="tags-item-name">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['tags', ],
  data() {
    return {
      list1: [],
      list2: [],
    }
  },
  watch: {
    // 监听数据变化
    tags: {
      handler(newVal, oldVal) {
        console.log('newVal', newVal)
        let list1 = 0
        let list2 = 0
        // 获取数据
        newVal.forEach((t) => {
          if (list1 <= list2) {
            this.list1.push(t)
            list1 += 50 + 17 * t.length
          } else {
            list2 += 50 + 17 * t.length
            this.list2.push(t)
          }
        })
      },
      deep: true,
      immediate: true
    },
   
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.guide-box {
  transition: all 0.5s;
  overflow: hidden;
}
.guide {
  z-index: 12;
  position: relative;
  transform: translateY(13 * 0.75px);
  height: 180*0.75px;
  .guide-title {
    height: 55 * 0.75px;
    position: relative;
    padding: 0 12 * 0.75px;
    justify-content: space-between;
    background: linear-gradient(179deg, #fff4d8 -4.5%, #ffe6a6 35.95%);
    .guid-title-icon {
      width: 109 * 0.75px;
      height: 68 * 0.75px;
      position: absolute;
      bottom: 0;
      left: 0px;
    }
    .guid-title-text {
      font-weight: bold;
      margin-left: 112 * 0.75px;
      font-size:16*0.75px;
      color: rgba(161, 91, 40, 1);
    }
    .guid-title-btn {
      width: 80 * 0.75px;
      height: 30 * 0.75px;
      padding: 0 8 * 0.75px;
      border-radius: 999em;
      background: #a15b28;
      color: #fff;
      font-size: 12 * 0.75px;
      .right-arrow {
        width: 12 * 0.75px;
        height: 12 * 0.75px;
        border-radius: 999em;
        background-color: #fff;
        margin-left: 4 * 0.75px;
      }
    }
  }
  .tags-scroll {
    width: 100%;
    height:90px;
    background-color: #fff;
    overflow: hidden;
    .tags-list {
      width: max-content;
      margin-top: 16 * 0.75px;
      margin-left: 16 * 0.75px;
      .tags-item {
        height: 40 * 0.75px;
        padding: 0px 12 * 0.75px;
        font-size: 16 * 0.75px;
        font-weight: bold;
        color: rgba(161, 91, 40, 1);
        border-radius: 8 * 0.75px;
        border: 1 * 0.75px solid #ffe193;
        background: #fff8e8;
        margin-right: 12 * 0.75px;
        .tags-item-icon {
          width: 16 * 0.75px;
          height: 16 * 0.75px;
          margin-right: 6 * 0.75px;
        }
      }
    }
    .tags-list + .tags-list {
      margin-top: 12 * 0.75px;
    }
  }
}
</style>
